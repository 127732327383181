<!-- ----------------------------------------------------------------------- -->
<!--
name    : ESTIMATES VIEW

type    : view

route   : /estimates
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="estimates-view">
    <!-- router-view will be replaced with whichever route is active -->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'EstimatesView',
    components: {},
  };
</script>
